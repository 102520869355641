.MemoHebdo__GridCategory {
    display: flex;
    gap: 10px 10px;
    flex-wrap: wrap;
    place-content: center;
    margin-top: 50px;
}

.MemoHebdo__GridCategory>img {
    width: 20%;
    cursor: pointer;
}

.MemoHebdo__GridCategory>img:hover {
    opacity: 80%;
}

.MuiBadge-root {
    /* position: relative;
    left: -18px;*/
    top: -17px;
}

.MemoHebdo__Modal {
    width: 80% !important;
    height: 80%;
    overflow: auto;
}
.MemoHebdo__Filter{
    justify-content: center;
        display: flex;
}