/* Fab Button */
.FabButton {
    display: inline-block;
}

.FabButton.AddRecipes {
    position: absolute;
    display: block;
    left: -75px;
    top: 0;
}

.FabButton.AddRecipes {
    position: absolute;
    display: block;
    left: -70px;
    top: 0;
}

.FabButton.Settings {
    position: absolute;
    display: block;
    left: 8.5%;
    top: 15px;
}

.FabButton.EditRecipeList {
    position: absolute;
    display: block;
    left: -50px;
    top: -30px;
}

.FabButton.EditRecipe {
    position: absolute;
    display: block;
    left: -55px;
    top: 0;
}

/* Expand root DIV to fill the entire screen height */
.RecipesView_Container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 130px);
    /* 130px == header size plus margin */
    transition: all 0.5s ease-out;
}

/* Center the container on the page */
.RecipesHeader {
    position: relative;
    width: 75%;
    margin: 20px 12.5% 20px;
}

.RecipesView__GridCategory {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    /* Center the items horizontally */
    margin: 0 auto 0 auto;
    /* Center the container and add top margin */
    background-color: #ffefc6;
    padding: 10px 10% 50px 10%;
    width: 100%;
    border-radius: 8px;
    /* Optional rounded corners */
    transition: all 0.5s ease-out;
}

.RecipesView__GridCategory .TriangleDecoration {
    position: absolute;
    top: -28px;
    left: 0;
    width: 100%;
    height: 30px;
    /* Height of the triangle */
    background: transparent;
    clip-path: polygon(0 30px, 100% 0, 100% 30px);
    background-color: #ffefc6;
    /* Color of the triangle */
}

/* Style each category with a box */
.RecipesCategory {
    margin: 40px 5px 0;
    padding: 15px;
    flex: 0 0 auto;
    position: relative;
    /* Prevent shrinking or growing */
    text-align: center;
    background-color: white;
    border-radius: 15px;
    transition: transform 0.2s;
    /* Smooth transition for hover effect */
    height: 75px;
    width: 150px;
    /* Fixed width for each category */
    cursor:pointer;
}

.RecipesView__Footer {
    width: 100%;
    height: 100%;
    background-color: #ffefc6;
}

.RecipesCategory:hover {
    transform: scale(1.05);
    /* Slight zoom on hover */
}

.RecipeView__ImageCategory {
    position: relative;
    width: 80%;
    height: 1px;
    margin-bottom: 25px;
}

.RecipeView__ImageCategory img {
    position: absolute;
    left: 15px;
    top: -32px;
    width: 55px;
}

.RecipeView__ImageCategory h6 {
    position: absolute;
    font-size: 1.5rem;
    left: 75px;
    top: -20px;
    font-weight: 600;
}

.RecipesCategory img {
    position: absolute;
    transform: translate(22.5px, -60%);
    width: 75px;
    /* Image size */
    height: auto;
    display: block;
    margin: 0 auto;
    padding-bottom: 10px;
    /* Space between image and text */
}

.RecipesCategory h6 {
    position: absolute;
    top: 55%;
    left: 0;
    display: flex;
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0;
    /* text-transform: uppercase; */
    justify-content: center;
    align-items: flex-start;
    height: auto;
    width: 100%;
    line-height: 1rem;
}

.RecipeView__Recipes {
    position: relative;
    background-color: #e5faff;
    width: 75%;
    margin: 30px 12.5% 0 12.5%;
    /*
    min-height: 300px;
    clip-path: inset(0 0 100% 0);
    animation: expand 0.5s ease-out forwards;
    transform-origin: top;*/
}

.RecipeView__Recipes .TriangleDecoration {
    position: absolute;
    top: -28px;
    left: 0;
    width: 100%;
    height: 30px;
    /* Height of the triangle */
    background: transparent;
    clip-path: polygon(0 30px, 100% 0, 100% 30px);
    background-color: #e5faff;
    /* Color of the triangle */
}

.RecipeList {
    justify-content: center;
    padding: 0 2% 30px 2%;
}

.RecipeList .CategoryLoadingSpinner {
    height: 2rem;
    display: block;
}

.RecipeCard {
    width: 10%;
    min-width: 175px;
    max-width: 100%;
    flex-basis: auto;
    padding: 10px;
}

.RecipeCard div {
    box-shadow: none;
    border-radius: 15px;
}

.RecipeCard img {
    position: relative;
    left: -12.5%;
    width: 125%;
}

.RecipesCard.active {
    background-color: #ffcc00;
}

.RecipeCard .RecipeCardContent {
    min-height: 75px;
    padding: 5px;
}

.RecipeCardContent h4 {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 75px;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.25;
    margin-bottom: 0;
    text-transform: capitalize;
}

.RecipeView__Recipe {
    position: relative;
    width: 70%;
    margin: 0 15%;
}

.RecipeView__Recipe .RecipeLoading {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 9999;
}

.RecipeView__Recipe .RecipeViewLoadingSpinner {
    position: absolute;
    top: calc(50% - 2.5rem);
    left: calc(50% - 2.5rem);
    transform: translate(-50%,-50%);
}

.RecipeView__Recipe .RecipeMediaContainer {
    position: relative;
}

.RecipeView__Recipe .RecipeMediaContainer>div {
    padding: 0;
}

.RecipeView__Recipe .RecipeVideoPlayButton {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.RecipeView__Recipe .RecipeVideoPlayButton:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px; /* Adjust as needed */
    height: 120px; /* Adjust as needed */
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 50%;
}

.RecipeView__Recipe .RecipeVideoPlayButton:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 60px solid #fff; /* Color of the play button */
}

.RecipeView__Recipe .RecipeLoadingSpinner {
    position: absolute;
    top: calc(50% - 2.5rem);
    left: calc(50% - 2.5rem);
    transform: translate(-50%,-50%);
}

.RecipeView__Recipe .RecipeImage {
    min-height: 20rem;
}

.RecipeView__Recipe .RecipeExtraImage {
    margin-bottom: 0.5rem;
}

.RecipeView__Recipe h1,
.RecipeView__Recipe h2 {
    text-transform: capitalize;
    font-weight: 600;
}

.RecipeView__Recipe>div {
    display: flex;
}

.RecipeImageColumn {
    padding: 10px;
    width: 50%;
}

.RecipeMethodColumn {
    padding: 10px;
    width: 50%;
}

.RecipeMethodColumn .RecipeMethod p {
    margin-bottom: 1rem;
}

.ButtonGroup {
    margin-bottom: 20px;
}

.button-inline {
    display: inline-block;
    margin-right: 10px;
    /* Add space between buttons */
}

/* CSS Animations */
@keyframes expand {
    0% {
        clip-path: inset(0 0 100% 0);
    }

    100% {
        clip-path: inset(0 0 0 0);
    }
}

/* Styles for tablets */
@media (max-width: 1024px) {
    .RecipesHeader {
        width: 90%;
        margin: 0 5%;
    }

    .RecipesView__GridCategory {
        padding: 10px 5% 50px 5%;
    }

    .RecipeView__Recipes {
        position: relative;
        background-color: #e5faff;
        width: 90%;
        margin: 30px 5% 0 5%;
    }

    .RecipeView__Recipe {
        width: 90%;
        margin: 0 5%;
    }
    
    .FabButton.AddRecipes {
        position: initial;
        display: block;
        margin-top: 15px;
    }
    
    .FabButton.Settings {
        top: -10px;
        left: 10px;
    }
    
    .FabButton.EditRecipeList {
        position: absolute;
        display: block;
        left: 10px;
        top: -30px;
    }
    
    .FabButton.EditRecipe {
        left: 0;
        top: 10px;
    }
}



/* Styles for phones */
@media (max-width: 768px) {
    .RecipeView__Recipe>div {
        display: block;
    }

    .RecipeImageColumn {
        width: 100%;
    }

    .RecipeMethodColumn {
        width: 100%;
    }

    .RecipesHeader {
        width: 90%;
        margin: 0 5%;
    }

    .RecipesView__GridCategory {
        padding: 10px 0 50px 0;
    }

    .RecipeView__Recipes {
        position: relative;
        background-color: #e5faff;
        width: 93%;
        margin: 30px 3.5% 0 3.5%;
    }

    .RecipeView__Recipe {
        width: 90%;
        margin: 0 5%;
    }
}

/* Styles for small phones */
@media (max-width: 415px) {
    .RecipeView__Recipe .RecipeImage {
        min-height: 10rem;
    }

    .RecipeCard {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
    }

    .RecipeCard>div {
        width: 100%;
        max-width: none;
    }

    .RecipesCategory {
        width: 125px;
    }

    .RecipesCategory img {
        transform: translate(0, -60%);
    }
}