.Emplacement__Modal {
    padding: 0 !important;
    width: 80% !important;
    height: 70% !important;
    overflow: auto;
}

.Emplacement__Modal>h5 {
    background-color: #0d6efd !important;
    height: 5%;
}

.Emplacement__Modal__grid  {
    padding: 10px;
}