.Login__toggleButton {
    justify-content: flex-end;
    padding: 10px;
}

.Login__Modal {
    position: "absolute";
    top: "50%";
    left: "50%";
    transform: "translate(-50%, -50%)";
    height: 800;
    width: 800;
    max-height: 80%;
    border: "2px solid #000";
    padding: 4;
}