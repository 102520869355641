.EditIconRecipe{
    position: absolute;
        top: -186px;
        right: -157px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 10;
        transition: background-color 0.2s ease;
}

.EditIconRecipe:hover {
    background-color: #ffebee;
}