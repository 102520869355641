.ArticleProperties__date{
    display: flex;
}

.ArticleProperties__Visibility{
    display: flex;
    width: 100%;
    max-height: 25vw;
    overflow: auto;
    margin-top: 25px;
    margin-bottom: 25px;
}

.ArticleProperties__Province{
    width: 100%;
}

.ArticleProperties__Department{
    width: 100%;
}
.ArticleProperties__Modal{
    overflow: auto;
    height: 80%!important;
}

.ArticleProperties__date__datePicker{
    display: flex;
    width: 50%;
    flex-direction: column;
}

.ArticleProperties__date__button{
    display: flex;
        align-items: center;
        margin-left: 150px;
}