.article__main{
    margin: 10px;
    position: relative;
}

.article__main__detailArticle{
    position: absolute;
        font-size: small;
        font-style: italic;
        right: 40px;
            bottom: -15px;
}

.article__main__Pastillebadge{
    position: relative;
        top: 12px!important;
        left: -10px;
}