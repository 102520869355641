.EditArticle__Editor {
    margin-top: 25px;
    display: flex;
    width: 90%;
}

.ArticleEditorFr{
    margin-top: 25px;
        margin-bottom: 25px;
}

.ArticleEditorEn {
    margin-top: 25px;
    margin-bottom: 25px;
}

.EdditionInProgress__Main {
    min-width: 25%;
    margin: 10px;
    height: 35vw;
}

.EdditionInProgress__Treeview {
    height: 100%;
}

.css-1gjrlho {
    height: 100% !important;
}

.Editor__Button {
    display: flex;
    justify-content: space-between;
}

.Editor__ButtonBar {
    display: flex;
    flex-wrap: wrap;
}

.Editor__ButtonBar>button {
    margin-left: 25px;
}

.Editor__ButtonBar__toggleButton {
    width: 20px;
}

