/*.ReportItemDashboard__main{
    justify-content: space-between;
}

.ReportItemDashboard__DashboardReportButon{
    display: flex;
    margin-right: 5px;
}

.ReportItemDashboard__DashboardReportInfo{
    display: flex;
    margin-left: 5px;
}

.ReportItemDashboard__DashboardReportInfo__BOX {
    margin-right: 20px;
   
}

/* ReportItemDashboard.css */

.ReportItemDashboard__main {
    justify-content: space-between;

    /* Adds space between rows */
}

.ReportItemDashboard__DashboardReportInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
        padding: 5px;
    /* Adds space between items */
    width: 100%;
}

.ReportItemDashboard__DashboardReportInfo__BOX {
    flex: 1;
    /* Allows each box to grow equally */
    margin-right: 0;
    /* Remove right margin for equal width */
}

.ReportItemDashboard__DashboardReportButon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (min-width: 600px) {
    .ReportItemDashboard__DashboardReportButon {
        flex-direction: row;
        align-items: center;
    }

    .ReportItemDashboard__DashboardReportButon Button {
        margin-left: 16px;
        /* Space between buttons in row layout */
    }
}

@media (min-width: 960px) {
    .ReportItemDashboard__main {
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
        /* Space between columns */
    }

    .ReportItemDashboard__DashboardReportInfo {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        /* Space between items in row layout */
    }

    .ReportItemDashboard__DashboardReportInfo__BOX {
        flex: 1;
        /* Ensures equal width distribution */
        margin: 0;
        /* Remove margins for equal width */
    }
}