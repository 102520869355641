.EditorSpecialMessage__date__datePicker {
    display: flex;
    align-items: baseline;
}

.EditorSpecialMessage__EditorFr {
    margin: 25px;
}

.EditorSpecialMessage__EditorEn {
    margin: 25px;
}

.EditorSpecialMessage__date__datePicker__publicationDate,
.EditorSpecialMessage__date__datePicker__endPublishedDate {
    display: flex;
    align-items: center;
    margin: 20px;
}

.EditorSpecialMessage__date__datePicker__publicationDate>label {
    margin: 10px;
}

.EditorSpecialMessage__date__datePicker__endPublishedDate>label {
    margin: 10px;
}