.Filter__main {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

.Filter__date {
    display: flex;
    align-items: baseline;
}

.Filter__date__datePicker {
    display: flex;
    align-items: baseline;
}

.Filter__date__datePicker>label {
    margin-left: 10px;
    margin-right: 10px;
}

.Filter__Search {
    display: flex;
    margin: 10px;
    align-items: center;
}