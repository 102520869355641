.CreateShareholder__Modal {
    padding: 0 !important;
    width: 50% !important;
    height: 85% !important;
    overflow: auto;
}

.CreateShareholder__Modal>* {
    padding: 15px;
}

.CreateShareholder__Modal>h5 {
    background-color: #0d6efd !important;
    padding: 5px;
}