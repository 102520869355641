.RecipesCategory>img {
    width: 20%;
    cursor: pointer;
}
.RecipesCategory.active {
    background-color: #ffcc00;
    /* Background color when active */
}

.EditIcon {
    position: absolute;
    top: -35px;
    right: -82px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: background-color 0.2s ease;
}

.DeleteIcon:hover {
    background-color: #a1999a;
}

.DeleteIcon {
    position: absolute;
    top: -5px;
    right: -72px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: background-color 0.2s ease;
}

.EditIcon:hover {
    background-color: #a1999a;
}

.ToggleIcon:hover {
    background-color: #a1999a;
}

.ToggleIcon {
    position: absolute;
    top: 25px;
    right: -92px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: background-color 0.2s ease;
}

@media only screen and (max-width: 800px) {
    .RecipesCategory {
        display: flex;
        width: 40%;
    }
}

.RecipesCategory {
    position: relative;
    /* Add this if not already present */
}