.Category__main {
    display: flex;
    width: 22%;
}

.Category__main > img{
    width: 80%;
    
}

@media only screen and (max-width: 800px) {
    .Category__main {
            display: flex;
            width: 40%;
        }
}