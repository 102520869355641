.ComplainForm__Select {
    margin: 10px;
}

.ComplainForm__TextField {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.ComplainForm__Editor {
    margin: 25px;
}

.ComplainForm__UploadFiles {
    margin: 10px;
}

.ComplainForm__UploadedFiles {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    /* Added to ensure images wrap properly */
}

.ComplainForm__UploadedFileItem {
    position: relative;
    margin: 10px;
    display: inline-block;
}

.ComplainForm__UploadedFileItem>img {
    width: 150px;
    /* Adjust as needed */
    margin: 2px;
    display: block;
}

.ComplainForm__UploadedFileItem .ComplainForm__RemoveIcon {
    position: absolute;
    top: -8px;
    right: -15px;
    color: #ff4d4d;
    background-color: white;
    border-radius: 50%;
    padding: 2px;
    z-index: 1;
    /* Ensure it appears above the image */
}

.ComplainForm__UploadedFileItem .ComplainForm__RemoveIcon:hover {
    background-color: #ffcccc;
}