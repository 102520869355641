.CompanyManagement__Modal {
    padding: 0 !important;
    width: 80% !important;
    height: 70% !important;
    overflow: auto;
}

.CompanyManagement__Modal>h5 {
    background-color: #0d6efd !important;
    height: 5%;
}

.CreateShareholder__AccordionShareholder {
    width: 100%;
    margin: 10px;
}

.CreateShareholder__AccordionShareholder__Title {
    background-color: #0d6efd;
    font-weight: bold;
}

.CreateShareholder__AccordionShareholder__Details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}